<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CCard>
          <CCardHeader>
            Cuadrantes
            <CButton
              v-if="checkPermissions(['edit_cuadrantes'])"
              color="primary"
              size="sm"
              class="m-1"
              @click="updateCuadrante(null, $event)"
            >
              Agregar
            </CButton>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              v-show="!loading"
              hover
              striped
              :items="items"
              :fields="fields"
              :items-per-page="pagination.perPage"
              index-column
              clickable-rows
            >
              <template #pais_id="data">
                <div>
                  {{filterPaises(data)}}
                </div>
              </template>
              <template #actions="data">
                <div v-if="checkPermissions(['edit_cuadrantes'])">
                  <CButton
                    color="warning"
                    size="sm"
                    class="m-1"
                    @click="updateCuadrante(data.item, $event)"
                  >
                    <font-awesome-icon icon="user-edit" />
                  </CButton>
                  <CButton
                    color="danger"
                    size="sm"
                    class="m-1"
                    @click="deleteCuadrante(data.item.id, $event)"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </CButton>
                </div>
                <td v-else>
                  --
                </td>
              </template>
            </CDataTable>
            <div 
              v-if="loading"
              class="d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;">
              <CSpinner
                style="width:4rem;height:4rem;"
                color="danger"
              />
              <p class="text-secondary my-2">Cargando datos ...</p>
            </div>
            <Pagination :data="pagination" :callback="paginate"/>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
    <EditCuadrante :showModal="showModal" :comunas="comunas" :paises="paises" :cuadrante="updatingCuadrante" :closeModal="() => closeModal()"/>
  </CRow>
</template>

<script>
import Pagination from '../../helpers/Pagination'
import { getCuadrantes, getComunas, deleteCuadrante, getPaises } from '../../api/cuadrantes'
import EditCuadrante from './EditCuadrante'
import checkPermissions from '../../helpers/userPermissions'

export default {
  name: 'Cuadrantes',
  components: {Pagination, EditCuadrante},
  data: () => {
    return {
      items: [],
      dataLoading: true,
      fields: [
        { key: 'id', label: '#' },
        { key: 'nombre', label: 'Cuadrante' },
        { key: 'descripcion', label: 'Descripción' },
        { key: 'pais_id', label: 'Pais' },
        { key: 'created_at', label: 'Creado' },
        { key: 'actions', label: 'Acciones' }
      ],
      pagination: {
        page: 1,
        perPage: 10,
        total: null
      },
      updatingCuadrante: null,
      showModal: false,
      loading: true,
      comunas: null,
      paises: null
    }
  },
  mounted(){
    this.getPaises()
    this.getCuadrantes()
    this.getComunas()
    if (!this.$store.state.userData.permisos.permissions.view_cuadrantes) {
      this.$router.push('/')
    }
  },
  methods: {
    checkPermissions,
    filterPaises(data){
      if(this.paises){
        const find = this.paises.find(item => item.id === data.item.pais_id)
        return find.name
      }
      return ''
    },
    getComunas(){
      const self = this
      getComunas()
        .then(function(resp){
          self.comunas = resp.comunas
          console.log(resp)
        })
        .catch(function(error){
          console.log(error)
        })
    },
    getPaises(){
      const self = this
      getPaises()
        .then(function(resp){
          self.paises = resp.paises
          console.log(resp)
        })
        .catch(function(error){
          console.log(error)
        })
    },
    paginate(page, perPage){
      this.pagination = {
        page: page,
        perPage: perPage,
        total: this.pagination.total
      }
      this.getCuadrantes()
    },
    closeModal(){
      this.showModal = false
      this.getCuadrantes()
    },
    deleteCuadrante(id, e){
      e.stopPropagation()
      const data = {id: id}
      const self = this
      deleteCuadrante(data)
        .then(function(resp){
          self.getCuadrantes()
        })
        .catch(function(error){
          console.log(error)
        })
    },
    updateCuadrante(cuadrante, e){
      e.stopPropagation()
      if(!cuadrante){
        cuadrante = {
          id: null,
          nombre: '',
          color: '#f3f3f3',
          descripcion: '',
          comuna_id: []
        }
      }
      this.updatingCuadrante = cuadrante
      this.showModal = true
    },
    getCuadrantes(){
      const data = {
        limit: this.pagination.perPage,
        page: this.pagination.page
      }
      const self = this
      this.loading = true
      getCuadrantes(data)
        .then(function(resp){
          self.items = resp.data
          console.log("items: ",self.items)
          self.pagination = {
            page: self.pagination.page,
            perPage: self.pagination.perPage,
            total: resp.total
          }
          self.loading = false
        })
        .catch(function(error){
          console.log(error)
        })
    },
    getBadge (status) {
      return status === 0 ? 'primary'
        : status === 1 ? 'danger'
        : status === 2 ? 'warning' : null
    },
    getEstado (status) {
      return status === 0 ? 'Activa'
        : status === 1 ? 'Cerrada'
        : status === 2 ? 'Acción Realizada' : null
    }
  }
}
</script>