import {endpoints} from './endpoints'
import request from '../helpers/request'

export function getCuadrantes(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getCuadrantes,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function getCuadrante(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getCuadrante,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function getComunas(){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getComunas,
      method: 'post'
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function getRegiones(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getRegiones,
      method: 'post',
	  data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function getRegionesByPais(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getRegionesByPais,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function getPaises(){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getPaises,
      method: 'post'
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function deleteCuadrante(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.deleteCuadrante,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function updateCuadrante(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.updateCuadrante,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

// Grupos Operacionales
export function getOperationalGroups(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getOperationalGroups,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function getUsersOperationalGroup(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getUsersOperationalGroup,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function addOperationalGroup(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.addOperationalGroup,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		})
		.catch(function (error) {
		    reject(error)
		  });
	})
}
export function deleteOperationalGroup(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.deleteOperationalGroup,
      method: 'delete',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		})
		.catch(function (error) {
		    reject(error)
		  });
	})
}

export function updateOperationalGroup(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.updateOperationalGroup,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		})
		.catch(function (error) {
		    reject(error)
		  });
	})
}

export function addUserToOperationalGroup(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.addUserToOperationalGroup,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		})
		.catch(function (error) {
		    reject(error)
		  });
	})
}

export function removeUserFromOperationalGroup(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.removeUserFromOperationalGroup,
      method: 'delete',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		})
		.catch(function (error) {
		    reject(error)
		  });
	})
}


export function getPaisesActivos(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getPaisesActivos,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function getRegionesActivas(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getRegionesActivas,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function getComunasActivas(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getComunasActivas,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}